@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.App {
  text-align: center;
}

.animate-from-left {
  opacity: 0;
  transform: translateX(-50px);
  animation-name: animateFromLeft;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.animate-from-left.animate {
  opacity: 1;
  transform: translateX(0);
}

@keyframes animateFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}