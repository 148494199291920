#TCSection {
    display: flex;
    justify-content: center;
}

#TCContainer {
    padding: 5rem 0;

    width: 60vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 142%;
}

