
#trainingBackgroundImage {
    height: calc(50vh - 5rem);
    background-blend-mode: darken;
    background-color: #cccccc;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* Remove background-attachment: fixed; */

    overflow-y: auto;

    color: white;

    padding-top: 3rem;
    padding-bottom: 3rem;
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: 142%;
}

#line2 {
    height: 0.2rem;
    background: white;
    width: 30%;
}

@media only screen and (max-width: 768px) {

    #contactImage {
        height: 20rem;
        font-size: 2rem;
    }
}

