#contactForm {
    height: auto;
    background-color: #F6E9E7;
}

#formMessage {
    height: 8rem;
    text-align: left;
}

#formTitle {

}

.form-control{
    text-align: center;
}
@media only screen and (max-width: 768px) {

}