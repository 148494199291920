.aboutElement{
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 1000;
    font-size: 3rem;
    line-height: 142%;
    width: 90%;
}

#aboutImage {
    height: 100%;
}

#aboutImage > img {
    width: auto;
    height: auto;
    max-height: 46rem;
}

#aboutMe{
    font-size: 1.5rem;
    font-weight: 500;
}

@media only screen and (max-width: 768px) {
    #aboutImage {
        display: none;
    }

    #about {
        height: auto;
        padding: 2rem 0;
    }
    
    #aboutQuote{
        font-size: 1.2rem;
    }

    #aboutMe{
        font-size: 1.25rem;
    }
}

@media only screen and (max-width: 1500px) {

    #aboutQuote{
        font-size: 1.2rem;
    }

    #aboutMe{
        font-size: 1.25rem;
    }
}