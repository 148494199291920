@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

section {
    padding: 0;
}

#inspirational{
    background: #F6E9E7;
}

.inspirationalElement{
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 1000;
    font-size: 3.25rem;
    line-height: 142%;
}
#inspirationalButton {
    font-weight: 400;
    font-size: 2rem;
}

.button-4 {
    appearance: none;
    background-color: #FAFBFC;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292E;
    cursor: pointer;
    display: inline-block;
    list-style: none;
    padding: 6px 16px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1),transform 0.3s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
}

.button-4:hover {
    background-color: #ccd0d9;
    text-decoration: none;
    transition-duration: 0.1s;
    transform: scale(1.1);
}

.button-4:disabled {
    background-color: #FAFBFC;
    border-color: rgba(27, 31, 35, 0.15);
    color: #959DA5;
    cursor: default;
}

.button-4:active {
    background-color: #EDEFF2;
    box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
    transition: none 0s;
}

.button-4:focus {
    outline: 1px transparent;
}

.button-4:before {
    display: none;
}

.button-4::-webkit-details-marker {
    display: none;
}

#inspirationalDiv {
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url("/public/media/pika-main.jpg");
    height: calc(100vh - 5rem) !important;
    background-size: cover;
    background-position: center;
    background-blend-mode: darken;
    background-color: #cccccc;
}

#inspirationalDiv::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border: solid transparent;
    border-width: 20px;
    border-top-color: #fff;
    z-index: 1;
    animation: arrowUpDown 1s ease-in-out infinite;
}

#inspirationalQuote{
    color: #FFFFFF;
}

@media only screen and (max-width: 768px) {

    #inspirationalQuote {
        font-size: 1.75rem;
    }

    #inspirationalSig {
        font-size: 1.5rem;
    }

    #inspirationalButton {
        font-size: 1.5rem;
    }

    #inspirationalDiv{
        background-position: left;
    }

}

@media only screen and (max-width: 1500px) {
    #inspirationalQuote {
        font-size: 2rem;
    }

    #inspirationalButton {
        font-size: 1.5rem;
    }
}

@keyframes arrowUpDown {
    0%, 100% {
        bottom: 0;
    }
    10%, 90% {
        bottom: 2px;
    }
    20%, 80% {
        bottom: 4px;
    }
    30%, 70% {
        bottom: 6px;
    }
    40%, 60% {
        bottom: 8px;
    }
    50% {
        bottom: 10px;
    }
}