#contactImage {
    height: 45vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) ,url("/public/media/treningi/prijava.jpg");
    background-blend-mode: darken;
    background-color: #cccccc;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    color: white;

    padding-top: 3rem;
    padding-bottom: 3rem;
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: 142%;

}

@media only screen and (max-width: 768px) {

    #contactImage {
        height: 20rem;
    }
}