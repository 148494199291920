.containerBackgroundTestemonial {
    background: #ffffff;

    margin: 0;
}

.testemonialTitle {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 2rem;
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 3.25rem;
    line-height: 142%;
}

#gallery-track {
    display: flex;
    position: relative;
    gap: 4vmin;
    width: 70vw;
    transform: translate(20%, 0);
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
}

#gallery-track > .card {
    object-fit: cover;
    object-position: center;
}

#gallery-track::-webkit-scrollbar {
    height: 12px;
}

#gallery-track::-webkit-scrollbar-thumb {
    background: #949494;
    border-radius: 10px;
}

#gallery-track::-webkit-scrollbar-track {
    background: #D9D9D9;
    border-radius: 10px;
}

#gallery-track::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.titleTestamonial{
    text-align: center;
    color: #111111;
    font-weight: 700;
    font-size: 2rem;
}

.cardImage {
    width: 50%;
    height: 50%;
}

.cardImage > img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.cardWrapper {
    width: 20vw;
    height: 100%;
    justify-content: center;
    background-color: #F6E9E7;
}

.testemonialCard{
    scroll-snap-align: start;
}

@media only screen and (max-width: 1500px) {
    .cardWrapper {
        width: 30vw;
    }
}

@media only screen and (max-width: 768px) {

    body {
        width: 100%;
        overflow-x: hidden;
      }

    .cardWrapper {
        width: 80vw;
    }

    .cardImage {
        width: 40%;
        height: 40%;
    }

    .testemonialCard{
        scroll-snap-align: start;
    }
}
