.aboutIndTrainingElement{
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 142%;
}

.aboutIndTrainingApendix {
    font-size: 1rem;
}

#line {
    height: 0.1rem;
    background: black;
    width: 15%;
}

#ListTitle {
    font-size: 4rem;
    font-weight: 600;
    line-height: 115%;
    text-align: left;
}

#trainingImage {
    border: black 1px solid;

}

#trainingText {
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 80%;
    margin: auto;
}

#aboutTrainingRoot {
    height: 50vh;
}

#aboutTrainingBackground {
    background: #F6E9E7;
    height: 100%;
}

#trainingReasonList {
    line-height: 90%;
    font-size: 1.25rem;
    text-align: left;
}

#trainingReason {
    color: white;
}
#trainingFlex {
    padding-top: 6rem;
}

#trainingSection {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.9)) ,url('../../public/media/pika_full.jpg');
    background-blend-mode: darken;
    background-color: #DDDDDD;
    background-repeat: no-repeat;
    background-size: cover;

    background-attachment:fixed;
    background-position: center;
}

#trainingCenik{
    display: inline-block;
    background: rgb(0,0,0,70%);
    padding: 2rem;
    border-radius: 40px;
    border: white 3px solid;
}

@media only screen and (max-width: 768px) {

    #ListTitle {
        font-size: 1rem;
    }

    #aboutTrainingRoot {
        height: 40rem;
    }

    #aboutTrainingBackground {
        height: auto;
    }

    #trainingText {
        font-size: 1.25rem;
    }
}


@media only screen and (max-width: 1500px) {

    #ListTitle {
        font-size: 3rem;
    }

    #aboutTrainingRoot {
        height: 35rem;
    }
}