@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.containerBackgroundQuestion{
    background: #E5ECF1;
}

.bigTitle{
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-family: 'Poppins', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 3.25rem;
    line-height: 142%;
}

.font{
    text-align: left;
    font-family: 'Poppins', serif;
    font-style: normal;
    line-height: 142%;
}

.title{
    color: #949494;
    font-weight: 700;
    font-size: 1.5rem;

}

.subtitle{
    color: #111111;
    font-weight: 700;
    font-size: 2rem;
}

.text{
    text-align: left;
    color: #111111;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    font-style: normal;
    line-height: 29px;
}

.offerImage > img {
    height: auto;
    width: 100%;
}

@media only screen and (max-width: 768px) {
   
    .title{
        text-align: center;
    }
    
    .subtitle{
        text-align: center;
    }

    .text{
        text-align: center;
    }

    .offerImage > img {
        height: 100%;
        width: auto;
    }
}

@media only screen and (max-width: 1500px) {
    .title{
        font-size: 1.25rem;
    }

    .subtitle{
        font-size: 1.5rem;
    }
}