.footerBackground{
    background: #E5ECF1;
    height: 15em;
}

.footerElement {
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 142%;
}

#logoName {
    font-size: 2rem;
}

#logoCoaching {
    color: #949494;
    font-size: 2rem;
}

#footerContactTitle {
    font-size: 1.5rem;   
}

#footerContactInfo {
    font-weight: 500;
}

#footerLinks{
    text-align: end;
}

.footerLinkElement {
    font-size: 1.5rem;
    color: #111111;
    display: inline-block;
    position: relative;
}

.footerLinkElement::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #555555;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.footerLinkElement:hover::after {
    color: #555555;
    transform: scaleX(1);
    transform-origin: bottom left;
}

#footerFacebookLink:hover {
    filter: opacity(0.5) drop-shadow(0 0 0 blue);
}

#footerInstagramLink:hover {
    filter: opacity(0.5) drop-shadow(0 0 0 hotpink);
}

#footerTiktokLink:hover {
    filter: opacity(0.5) drop-shadow(0 0 0 grey);
}

#footerTC {
    text-align: center;
    font-size: 1.2rem;
    width: auto;
}

#footerIconLinks > a {
    margin: 0 0.25rem;
}

@media only screen and (max-width: 768px) {
    .footerElement{
        font-size: 1rem;
    }

    .logoFont {
        font-size: 2rem;
    }

    .contactTitleFont {
        font-size: 1.5rem;
    }

    #footerLinks {
        display: none;
    }

    #footerContact {
        text-align: center;
    }
}

