.navbar {
    display: flex;
    padding: 0 3% 0 12%;
    background: #FFD1D1;
    position: relative;
    height: 5rem;
}

#navbarLinks{
    display: flex;
    justify-content: flex-end;
    height: 4rem;
    align-content: center;
    flex-wrap: wrap;
}

#navPrijava {
    background-color: #96C8ED;
}

#navPrijava:hover {
    background-color: #a1d1ff;
}

.navElement{
    display: flex;
    align-items: center;
    margin-left: 2rem;
    margin-right: 2rem;
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 142%;
}

.navLink {
    color: #111111;
    display: inline-block;
    position: relative;
}

.navLink::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #555555;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.navLink:hover::after {
    color: #555555;
    transform: scaleX(1);
    transform-origin: bottom left;
}

#navbarLogo > img {
    width: auto;
    height: auto;
    max-height: 4rem;
}

#navDropdown {
    display: flex;
    justify-content: center;
    height: 5rem;
}

.dropdown {
    position: absolute;
    top: 100%;
    display: none;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    font-size: 0.8rem;
}

.dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.dropdown li:hover {
    background-color: #f5f5f5;
}

.dropdown-link:hover {
    background-color: #f0f0f0;
}

.dropdown-wrapper {
    width: 200px; /* Adjust the width as needed */
}

.navElement:hover .dropdown {
    display: block; /* Show the dropdown on hover */
}

.navbar-mobile {
    display: none;
}

@media only screen and (max-width: 768px) {

    .navbar {
        display: none;
    }

    .navbar-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 3%;
        background: #FFD1D1;
        position: relative;
        height: 5rem;
    }

    .dropdown {
        position: relative;
        background: #E5ECF1;
        display: none;
        box-shadow: none;
        padding: 0.25rem 0;
        font-size: 1rem;
    }

    .dropdown ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .dropdown li {
        padding: 0.25rem 0;
        border-bottom: none;
        cursor: pointer;
    }

    .dropdown-wrapper {
        width: 100%;
    }

    #mobileMenu {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    #mobileMenuContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #E5ECF1;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        font-size: 0.8rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    #mobileMenuContent div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0;
    }
    .fadeIn {
        -webkit-animation: fadeIn 0.5s ease-in-out;
        -o-animation: fadeIn 0.5s ease-in-out;;
        animation: fadeIn 0.5s ease-in-out;;
    }

    .fadeOut {
        -webkit-animation: fadeOut 0.5s ease-in-out;
        -o-animation: fadeOut 0.5s ease-in-out;;
        animation: fadeOut 0.5s ease-in-out;;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(-30px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes fadeOut {
        from {
            opacity: 1;
            transform: translateY(0);
        }
        to {
            opacity: 0;
            transform: translateY(-30px);
        }
    }
}

@media only screen and (max-width: 1500px) {
    .navElement {
        font-size: 1.5rem;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}